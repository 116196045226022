import React, { Component } from 'react'

class Auto extends Component {
  componentDidMount () {
  }

  render () {
    return (
      <>
      </>
    )
  }
}
export default Auto
